import React from "react"
import PropTypes from "prop-types"
import {createPortal} from "react-dom"

import * as util from "../../util/data"

import ReactHintFactory from 'react-hint'
const ReactHint = ReactHintFactory(React)

class CopyableText extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      randomId: Math.floor(Math.random()*16777215).toString(16),
    }
  }

  tooltipDataAttrName = () => {
    return `data-${this.state.randomId}-tooltip`
  }

  tooltipTrigger = () => {
    return {
      [this.tooltipDataAttrName()]: "", // link up the tooltip trigger to the tooltip display
    }
  }

  renderTooltip = (target, content) => {
    return (
      <span className="tooltip-content copy-tooltip-content copyable-text-tooltip-content"
            ref={(e) => this.tooltipContentRef = e}>
        Click to copy to clipboard
      </span>
    )
  }

  onClickCopy = (e) => {
    e.preventDefault()

    // save focus target
    this.prevActiveElement = document.activeElement

    util.copyToClipboard(this.props.textToCopy)

    if(this.props.onCopy) this.props.onCopy(e)
    // we want immediate update, without waiting for state change
    // (as the tooltip might not redraw)
    if (this.tooltipContentRef) {
      this.tooltipContentRef.textContent = "Copied"
    }

    // return focus for ESC to trigger properly
    this.prevActiveElement.focus()
    delete this.prevActiveElement
  }

  renderHint() {
      return (
        <ReactHint
          position={this.props.tooltipPosition}
          onRenderContent={this.renderTooltip}
          className="tooltip"
          attribute={this.tooltipDataAttrName()}
          delay={0}
          persist={true}
          events
        />
      )
  }

  render() {
    return (
      <React.Fragment>
        <span
          className={"copyable-text " + this.props.className}
          {...this.tooltipTrigger()}
          onClick={this.onClickCopy}
        >{this.props.textToDisplay}</span>

        {this.props.tooltipContainer != null ? createPortal(this.renderHint(), this.props.tooltipContainer) : this.renderHint()}
      </React.Fragment>
    )
  }
}

CopyableText.propTypes = {
  textToDisplay: PropTypes.string,
  textToCopy: PropTypes.string,
  className: PropTypes.string,

  // Container for the ReactHint element. This will control where the
  // tooltip container actually appears in the DOM.
  tooltipContainer: PropTypes.object,
  suppressToolTip: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  onCopy: PropTypes.func,
}

CopyableText.defaultProps = {
  className: "",
  tooltipPosition: 'top',
}

export default CopyableText
