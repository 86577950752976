import React from "react"
import PropTypes from "prop-types"
import AssetTile from "./AssetTile";

export default class AssetGrid extends React.Component {
  render() {
    const data = this.props.data
    const numRows = data.length <= this.props.targetItemsPerRow ? 1 : this.props.maxRows
    const itemsPerRow = Math.ceil(data.length / numRows)
    const structuredItems = [];
    for (let r = 0; r < numRows; r++) {
      const rowOffset = r * itemsPerRow;
      const rowItems = [];
      for (let a = rowOffset; a < rowOffset + itemsPerRow; a++) {
        if (a >= data.length) {
          break
        }
        rowItems.push(data[a])
      }
      structuredItems.push(rowItems)
    }

    return (
      <div className="asset-grid">
        {structuredItems.map((row, rowIndex) => (
          <div key={rowIndex} className="asset-row">
            {row.map((asset, assetIndex) => (
              <AssetTile key={assetIndex}
                         node={asset}
                         tileClickHandler={this.props.onClickTile}
              >asset</AssetTile>
            ))}
          </div>
        ))}
      </div>
    )
  }
}

AssetGrid.propTypes = {
  data: PropTypes.array,
  maxRows: PropTypes.number,
  targetItemsPerRow: PropTypes.number,
  onClickTile: PropTypes.func,
}

AssetGrid.defaultProps = {
  maxRows: 2,
  targetItemsPerRow: 5,
}



