import React from "react"
import PropTypes from "prop-types"

import {CSSTransitionGroup} from 'react-transition-group'
import possessive from "@wardrakus/possessive"

import AssetDetails from './AssetDetails'
import EmptyAssetsView from './EmptyAssetsView'
import AssetMap from '../graph/AssetMap'

import * as format from '../../util/formatting'
import * as purchaseStatus from '../../util/builder/purchase_status'
import * as purchaseKind from '../../util/builder/purchase_kind'
import * as purchaseOrderHelpers from '../../util/builder/purchase_order'
import * as privacyUtils from "../../util/privacy"
import {getSampleDac} from "../../util/sampledata"
import AssetGrid from "../graph/AssetGrid";
import {PrivacyField} from "../graph/PrivacyField";
import * as formatting from "../../util/formatting";

class DacDetails extends React.Component {
  constructor(props) {
    super(props)

    this.hasTopUpOrder = this.hasTopUpOrder.bind(this)
    this.getTileData = this.getTileData.bind(this)
    this.shouldShowSettingsBtn = this.shouldShowSettingsBtn.bind(this)
    this.updateGridDimensions = this.updateGridDimensions.bind(this)
    this.handleOnClickTile = this.handleOnClickTile.bind(this)
    this.handleAssetOnClose = this.handleAssetOnClose.bind(this)
    this.handleAssetOnNext = this.handleAssetOnNext.bind(this)
    this.handleAssetOnPrevious = this.handleAssetOnPrevious.bind(this)
    this.onSwitchTopUp = this.onSwitchTopUp.bind(this)

    this.dacGridContainer = React.createRef()

    const queryParams = new URLSearchParams(window.location.search);

    // Check if 'sampleDac' is present in the query string
    const sampleDacId = queryParams.get('sampledac');
    let sampleDac = null;
    if (sampleDacId !== null && sampleDacId !== undefined) {
      sampleDac = getSampleDac(sampleDacId)
    }

    this.state = {
      displayAssetDetails: false,
      selectedCurrency: null,
      dacGridReadyForDisplay: false,
      dacGridWidth: null,
      dacGridHeight: null,
      sampleDac: sampleDac,
    }
  }

  hasTopUpOrder() {
    return this.props.purchaseOrder && this.props.purchaseOrder.kind === purchaseKind.TOP_UP
  }

  getTileData() {
    let tileDataWithBalances = []
    let tileDataWithZeroBalances = []
    const dacRepresentation = this.getDacRepresentation()

    Object.keys(dacRepresentation.currencies).forEach((currency, i) => {
      const actualBalance = Number.parseFloat(dacRepresentation.currencies[currency].balance)
      const cadValue = Number(parseFloat(dacRepresentation.currencies[currency].cad_value).toFixed(2))
      const footerDecimals = 1
      if (cadValue > 0) {
        let percentage = 100.0 * parseFloat(cadValue) / parseFloat(dacRepresentation.cad_value)
        // if the percentage is less than the minimum value representable using 'footerDecimals', display
        // it as "<{min percent}"; otherwise, display the actual value rounded to 'footerDecimals' places
        let footerValue;
        if (Math.pow(10, footerDecimals) * percentage < 1.0) {
          footerValue = `<${format.percentage(Math.pow(10, -footerDecimals), footerDecimals)}`
        } else {
          footerValue = format.percentage(percentage, footerDecimals)
        }
        tileDataWithBalances.push({
          "currency": currency,
          "public_address": dacRepresentation.currencies[currency].public_address,
          "proportion": percentage,
          "balance": actualBalance,
          "cad_value": cadValue,
          "header": currency,
          "footer": footerValue,
          "size": percentage,
        })
      } else {
        tileDataWithZeroBalances.push({
          "currency": currency,
          "public_address": dacRepresentation.currencies[currency].public_address,
          "proportion": 0,
          "balance": 0,
          "cad_value": 0,
          "header": currency,
          "footer": format.percentage(0, footerDecimals),
          "size": 0,
        })
      }
    })


    // sort in descending order, then by currency symbol
    tileDataWithBalances.sort((a, b) => {
      if (b.size === a.size) {
        return b.currency > a.currency ? -1 : 1
      }
      return b.size - a.size
    })

    return {nonZeroBalances: tileDataWithBalances, zeroBalances: tileDataWithZeroBalances}
  }

  getDacRepresentation() {
    if (this.props.dac.status === 'empty' || this.props.isTopUpViewSelected) {
      return purchaseOrderHelpers.purchaseOrderAsDac(this.props.purchaseOrder, this.props.assetSummary)
    }
    return {...this.props.dac, ...(this.state.sampleDac ? this.state.sampleDac : {})}
  }

  shouldShowSettingsBtn() {
    if (this.hasTopUpOrder()) {
      return this.props.isTopUpViewSelected && !purchaseStatus.isOrderSubmitted(this.props.purchaseOrder)
    }

    return this.props.purchaseOrder && !purchaseStatus.isOrderSubmitted(this.props.purchaseOrder)
  }

  updateGridDimensions() {
    this.setState({
      ...this.state,
      dacGridReadyForDisplay: true,
      dacGridWidth: this.dacGridContainer.current.offsetWidth,
      dacGridHeight: this.props.dacGridHeight,
    })
  }

  handleOnClickTile(selectedCurrency) {
    if (selectedCurrency) {
      this.setState({
        ...this.state,
        selectedCurrency: selectedCurrency,
        displayAssetDetails: true,
      })
    }
  }

  handleAssetOnClose() {
    this.updateGridDimensions()

    this.setState({
      ...this.state,
      displayAssetDetails: false,
    })
  }

  handleAssetOnNext() {
    var currentIndex = 0
    const tileData = this.getTileData().nonZeroBalances

    for (var i = 0; i < tileData.length; i++) {
      if (tileData[i].currency === this.state.selectedCurrency) {
        currentIndex = i
        break
      }
    }

    var nextIndex = (currentIndex + 1) % tileData.length

    this.setState({
      ...this.state,
      selectedCurrency: tileData[nextIndex].currency,
    })
  }

  handleAssetOnPrevious() {
    var currentIndex = 0
    const tileData = this.getTileData().nonZeroBalances

    for (var i = 0; i < tileData.length; i++) {
      if (tileData[i].currency === this.state.selectedCurrency) {
        currentIndex = i
        break
      }
    }

    var prevIndex = currentIndex - 1
    if (prevIndex < 0) {
      prevIndex = tileData.length - 1
    }

    this.setState({
      ...this.state,
      selectedCurrency: tileData[prevIndex].currency,
    })
  }

  onSwitchTopUp(shouldDisplayTopUp) {
    if (shouldDisplayTopUp) {
      this.props.onSwitchToTopUpView()
    } else {
      this.props.onSwitchToDacView()
    }
  }

  componentDidMount() {
    this.updateGridDimensions()
    window.addEventListener("resize", this.updateGridDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateGridDimensions)
  }

  render() {
    let mainContent;

    const tileData = this.getTileData()
    if (this.state.displayAssetDetails) {
      let primaryColor = ''
      let proportion = 0

      const allTileData = tileData.nonZeroBalances.concat(tileData.zeroBalances)
      allTileData.forEach((tile) => {
        if (tile.currency === this.state.selectedCurrency) {
          proportion = tile.proportion
          primaryColor = tile.bgcolor
        }
      })

      mainContent = (
        <AssetDetails
          user={this.props.user}
          currency={this.state.selectedCurrency}
          assetSummary={this.props.assetSummary[this.state.selectedCurrency]}
          primaryColor={primaryColor}
          proportion={proportion}
          data={this.getDacRepresentation().currencies[this.state.selectedCurrency]}
          onClose={this.handleAssetOnClose}
          onNext={this.handleAssetOnNext}
          onPrevious={this.handleAssetOnPrevious}/>
      )
    } else {
      mainContent = (
        <div className="dac-grid-container">
          <div className="section-title">
            <div className="top-up-nav-section">
              {this.props.user.type == "settlement_client" ? (
                <span>Your Assets In Custody</span>
              ) : (
                <span>Your Digital Asset Cache{this.state.sampleDac && (
                  <span className="warning" title={this.state.sampleDac.description}>sample data only: {this.state.sampleDac.name}</span>)}</span>
              )}
              {this.hasTopUpOrder() && (
                <div className="segmented-control top-up-switcher">
                  <div
                    className={"segment " + (this.props.isTopUpViewSelected ? 'inactive' : 'active')}
                    onClick={this.props.onSwitchToDacView}>Live
                  </div>
                  <div
                    className={"segment " + (this.props.isTopUpViewSelected ? 'active' : 'inactive')}
                    onClick={this.props.onSwitchToTopUpView}>Top-Up
                  </div>
                </div>
              )}
            </div>

            {this.shouldShowSettingsBtn() && (
              <div className="settings-icon" onClick={this.props.onEdit} title="Edit"/>
            )}

            {this.props.showSummaryAmounts && this.props.dac.cad_value > 0 && (
              <privacyUtils.PrivacyContext.Consumer>
                {privacyEnabled => (
                  <div className="summary-container">
                    <div className="amount" onClick={this.props.onTogglePrivacy}>
                      <PrivacyField text={formatting.money(this.props.dac.cad_value, 2)}/>
                    </div>
                    <div className="label privacy-container">
                      <span>Balance</span>
                      <div className={"privacy-button " + (privacyEnabled ? 'open' : 'closed')}
                           onClick={this.props.onTogglePrivacy}
                           title={privacyEnabled ? 'Show balance' : 'Hide balance'}/>
                    </div>
                  </div>
                )}
              </privacyUtils.PrivacyContext.Consumer>
            )}
          </div>

          <div className="divider"></div>
          <CSSTransitionGroup
            transitionName="animated-pane-fade"
            transitionAppear={true}
            transitionAppearTimeout={400}
            transitionEnter={false}
            transitionLeave={false}>

            <div className="dac-grid">
              {(this.state.dacGridReadyForDisplay && !(this.props.user.type == "settlement_client" && this.props.dac.cad_value === 0)) && (
                <React.Fragment>
                  {tileData.nonZeroBalances?.length > 0 && (<AssetMap
                    height={this.state.dacGridHeight / 2}
                    width={this.state.dacGridWidth}
                    data={tileData.nonZeroBalances}
                    onClickTile={this.handleOnClickTile}/>)}
                  {tileData.zeroBalances?.length > 0 && (
                    <AssetGrid
                      data={tileData.zeroBalances}
                      onClickTile={this.handleOnClickTile}></AssetGrid>
                  )}
                </React.Fragment>
              )}

              {(this.props.user.type == "settlement_client" && this.props.dac.cad_value === 0) && (
                <div style={{height: this.state.dacGridHeight}}>
                  <EmptyAssetsView/>
                </div>
              )}
            </div>

          </CSSTransitionGroup>
        </div>
      )
    }

    return (
      <div className="dac-details" ref={this.dacGridContainer}>
        {mainContent}
      </div>
    )
  }
}

DacDetails.propTypes = {
  dac: PropTypes.object,
  purchaseOrder: PropTypes.object,
  assetSummary: PropTypes.object,
  user: PropTypes.object,
  onEdit: PropTypes.func,
  isTopUpViewSelected: PropTypes.bool,
  onSwitchToDacView: PropTypes.func,
  onSwitchToTopUpView: PropTypes.func,
  showSummaryAmounts: PropTypes.bool,
  onTogglePrivacy: PropTypes.func,
  dacGridHeight: PropTypes.number,
}

DacDetails.defaultProps = {
  showSummaryAmounts: false,
  dacGridHeight: 487,
}

export default DacDetails
