import React from "react"
import PropTypes from "prop-types"

import * as d3 from 'd3';
import AssetTile from "./AssetTile";

class AssetMap extends React.Component {
  constructor(props) {
    super(props)

    this.treeMapContainer = React.createRef()

    let layoutMode = d3.treemapSquarify
    let minWeight = 5

    // Check if 'mode' and/or 'minweight' are present in the query string
    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('mode') === 'b') {
      layoutMode = d3.treemapBinary
    }
    const minWeightParamValue = parseFloat(queryParams.get('minweight'))
    if(minWeightParamValue) { // excludes undefined, null, NaN, or <= 0 cases
      minWeight = minWeightParamValue
    }

    this.state = {
      nodes: [],
      layoutMode: layoutMode,
      minWeight: minWeight
    }
  }

  componentDidMount() {
    this.renderTreeMap()
  }

  recalculateProportions(data) {
    const total = Math.round(data.reduce((sum, asset) => sum + asset.size, 0));
    const minPercentage = this.state.minWeight
    let belowThresholdTotal = 0;
    let aboveThresholdTotal = 0;

    data.forEach(d => {
      if (d.size < minPercentage) {
        belowThresholdTotal += minPercentage;
      } else {
        aboveThresholdTotal += d.size
      }
    })

    const availablePercentage = total - belowThresholdTotal
    return data.map(d => ({
      ...d,
      proportion: d.size < minPercentage ? minPercentage : (d.size / aboveThresholdTotal) * availablePercentage
    }))
  }

  renderTreeMap() {
    const reproportionedData = this.recalculateProportions(this.props.data)
    const containerElement = this.treeMapContainer.current;
    const width = containerElement.clientWidth;
    const height = containerElement.clientHeight;

    const container = d3.select(containerElement);
    container.selectAll("*").remove();

    const root = d3.hierarchy({
      name: "root",
      children: reproportionedData
    })
      .sum(d => d.proportion)

    d3.treemap()
      .tile(this.state.layoutMode)
      .size([width, height])
      .padding(6)(root)

    this.setState({
      ...this.state,
      nodes: root.leaves(),
    })
  }

  render() {
    const {nodes} = this.state;
    return (
      <React.Fragment>
        <div ref={this.treeMapContainer} className="tile-map new">
          {nodes.map((node, index) => (
            <div
              key={index}
              className={`tile-map-leaf ${node.data.currency}`}
              style={{
                position: "absolute",
                left: `${node.x0}px`,
                top: `${node.y0}px`,
                width: `${node.x1 - node.x0}px`,
                height: `${node.y1 - node.y0}px`,
              }}
            >
              <AssetTile
                node={node.data}
                class="map-tile"
                tileClickHandler={this.props.onClickTile}
              ></AssetTile>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
      ;
  }
}

AssetMap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  onClickTile: PropTypes.func,
}

export default AssetMap
