import React, { Component } from "react"
import PropTypes from "prop-types"
import GenericComponent from "react-stockcharts/lib/GenericComponent"
import { hexToRGBA, isDefined, isNotDefined, strokeDashTypes, getStrokeDasharray } from "react-stockcharts/lib/utils"

class VerticalCursor extends Component {
  constructor(props) {
    super(props)
    this.renderSVG = this.renderSVG.bind(this)
  }

  renderSVG(moreProps) {
    const { className } = this.props
    const lines = helper(this.props, moreProps)

    if (isNotDefined(lines)) return null

    const circleX = customX(this.props, moreProps)
    const circleY = this.context.chartConfig.yScale(this.props.yAccessor(moreProps.currentItem))

    const triangleTipX = customX(this.props, moreProps)
    const triangleTipY = this.context.chartConfig.height-6
    const triangleBottomLeftX = triangleTipX-4
    const triangleBottomLeftY = this.context.chartConfig.height
    const triangleBottomRightX = triangleTipX+4
    const triangleBottomRightY = this.context.chartConfig.height

    const trianglePoints = [
      [triangleTipX,         triangleTipY].join(','),
      [triangleBottomLeftX,  triangleBottomLeftY].join(','),
      [triangleBottomRightX, triangleBottomRightY].join(','),
    ].join(' ')

    return (
      <g className={`react-stockcharts-crosshair ${className}`}>

        {lines.map(({ strokeDasharray, ...rest }, idx) =>
          <line
            key={idx}
            strokeDasharray={getStrokeDasharray(strokeDasharray)}
            {...rest} />)}

        <polygon points={trianglePoints} fill={this.props.stroke} />

        <circle cx={circleX} cy={circleY} r="4" stroke={this.props.stroke} strokeWidth="1" fill="white" />
      </g>
    )
  }

  render() {
    return <GenericComponent
      svgDraw={this.renderSVG}
      clip={false}
      drawOn={["mousemove"]}
    />
  }
}

VerticalCursor.propTypes = {
  className: PropTypes.string,
  strokeDasharray: PropTypes.oneOf(strokeDashTypes),
  yAccessor: PropTypes.func,
}

VerticalCursor.contextTypes = {
  margin: PropTypes.object.isRequired,
  ratio: PropTypes.number.isRequired,
  chartConfig: PropTypes.object.isRequired,
}

function customX(props, moreProps) {
  const { xScale, xAccessor, currentItem, mouseXY } = moreProps
  const { snapX } = props
  const x = snapX
    ? Math.round(xScale(xAccessor(currentItem)))
    : mouseXY[0]
  return x
}

function defaultYAccessor(d) {
  return d.cad_value
}

VerticalCursor.defaultProps = {
  opacity: 1,
  strokeDasharray: "ShortDash",
  snapX: true,
  customX,
  yAccessor: defaultYAccessor,
}

function helper(props, moreProps) {
  const {
    mouseXY, currentItem, show, height, width
  } = moreProps

  const { customX, stroke, opacity, strokeDasharray } = props

  if (!show || isNotDefined(currentItem)) return null

  const x = customX(props, moreProps)

  const verticalLine = {
    x1: x,
    x2: x,
    y1: 0,
    y2: height,
    strokeDasharray, opacity,
  }
  return [verticalLine]
}

export default VerticalCursor
