import { v4 as uuidv4 } from 'uuid';

const sampleDacs = new Map([
  [0, {
    name: "empty 01",
    description: "5 currencies - all empty",
    cadValues: {
      btc: 0,
      xlm: 0,
      eth: 0,
      ltc: 0,
      usdc: 0,
    }
  }],
  [1, {
    name: "empty 02",
    description: "8 currencies - all empty",
    cadValues: {
      btc: 0,
      xlm: 0,
      eth: 0,
      ltc: 0,
      usdc: 0,
      bch: 0,
      xmr: 0,
      etc: 0,
    }
  }],
  [2, {
    name: "empty 03",
    description: "9 currencies - all empty",
    cadValues: {
      btc: 0,
      xlm: 0,
      eth: 0,
      ltc: 0,
      usdc: 0,
      bch: 0,
      xmr: 0,
      etc: 0,
      axia: 0,
    }
  }],
  [3,{
    name: "full 01",
    description: "5 currencies - all populated",
    cadValues: {
      btc: 1234.567,
      xlm: 89.01234,
      eth: 56789.1011,
      ltc: 12131.415161718,
      usdc: 1920.2122,
      bch: 23.24,
    }
  }],
  [4, {
    name: "full 02",
    description: "9 currencies - all populated",
    cadValues: {
      btc: 1234.567,
      xlm: 89.01234,
      eth: 56789.1011,
      ltc: 12131.415161718,
      usdc: 1920.2122,
      bch: 23.24,
      xmr: 23434.634,
      etc: 87345.345,
      axia: 4583.435,
    }
  }],
  [5, {
    name: "minimal 01",
    description: "2 currencies - 2/3+1/3",
    cadValues: {
      xlm: 3333.333,
      btc: 6666.667,
    }
  }],
  [6, {
    name: "minimal 02",
    description: "3 currencies - ~1/2+1/4+1/4",
    cadValues: {
      bch: 35000.00,
      xlm: 75000.00,
      btc: 40000.00,
    }
  }],
  [7, {
    name: "mixed 01",
    description: "5 currencies - ~1/2+1/4+1/4 & two empty",
    cadValues: {
      bch: 35000.00,
      xlm: 75000.00,
      btc: 40000.00,
      xmr: 0,
      etc: 0,
    }
  }],
  [8, {
    name: "mixed 02",
    description: "9 currencies - 4 populated / 5 empty",
    cadValues: {
      btc: 1234.567,
      xlm: 89.01234,
      eth: 56789.1011,
      ltc: 12131.415161718,
      bch: 23.24,
      usdc: 0,
      xmr: 0,
      etc: 0,
      axia: 0,
    }
  }],
])

function makeFakeWalletAddress() {
  return uuidv4().replaceAll('-', '').toUpperCase()
}

export const getSampleDac = function (key) {
  const parsedKey = parseInt(key)

  if (!isNaN(parsedKey) && sampleDacs.has(parsedKey)) {
    const selectedDac = sampleDacs.get(parsedKey)
    let cadValues = selectedDac.cadValues
    const defaultCadConversion = 1024

    const currencies = Object.entries(cadValues).reduce((acc, [k, v]) => {
      const balance = v / defaultCadConversion;
      acc[k] = {
        balance: balance,
        cad_value: v,
        public_address: makeFakeWalletAddress(),
        settled_balance: balance,
        settled_cad_value: v,
      }
      return acc
    }, {})
    const total_cad_value = Object.values(currencies).reduce((acc, c) => acc + c.cad_value, 0)
    return {
      name: selectedDac.name,
      description: selectedDac.description,
      cad_value: total_cad_value,
      settled_cad_value: total_cad_value,
      currencies: currencies
    }
  }

  return null
}
