import React from "react"
import PropTypes from "prop-types"
import {PrivacyField} from "./PrivacyField";
import {formatAssetValue, money} from "../../util/formatting";
import renderingUtils from "../../util/rendering";

export default class AssetTile extends React.Component {
  constructor(props) {
    super(props)

    this.contentBox = React.createRef()

    const node = this.props.node

    const formattedAssetValue = formatAssetValue(node.balance, node.currency, 14);
    this.state = {
      formattedValue: formattedAssetValue,
      displayValue: formattedAssetValue,
      formattedCadValue: money(node.cad_value, 2),
      showBaseValue: true,
      showConvertedValue: true,
    }
  }

  determineVisibility() {
    const textHeight = 12,
      font = "bold " + textHeight + "px Avenir Next, Lato, Calibri, Arial, sans-serif"

    const baseValueDims = renderingUtils.getTextDimensions(this.state.formattedValue, font),
      convertedValueDims = renderingUtils.getTextDimensions(this.state.formattedCadValue, font)
    const boxDimensions = {
      width: this.contentBox.current ? this.contentBox.current.clientWidth : 0,
      height: this.contentBox.current ? this.contentBox.current.clientHeight : 0,
    }


    const boxPaddingVert = 16;
    const boxPaddingHoriz = 4;
    const availableHeight = boxDimensions.height - boxPaddingVert * 2;
    const availableWidth = boxDimensions.width - boxPaddingHoriz * 2;

    let displayValue = this.state.formattedValue
    // check to see if the base asset value can be rounded to fewer decimal places to fit
    if (baseValueDims.width > availableWidth && convertedValueDims.width <= availableWidth) { // but only if the CAD value will fit
      const totalLengthReduction = availableWidth / baseValueDims.width;
      const targetLength = Math.floor(this.state.formattedValue.length * totalLengthReduction);
      const assetPattern = /^(\d+)\.(\d+)?(\s*[A-Za-z]+)$/
      const match = assetPattern.exec(this.state.formattedValue)

      const minDecimalPlaces = 1
      const decimalSeparatorSize = '.'.length // because I hate magic constants that much
      if (match && match[1].length + decimalSeparatorSize + match[3].length <= targetLength) {
        const roundedDecimalPlaces = targetLength - (match[1].length + decimalSeparatorSize + match[3].length)
        if (roundedDecimalPlaces >= minDecimalPlaces) {
          displayValue = `${Math.round(this.props.node.balance * Math.pow(10, roundedDecimalPlaces)) / Math.pow(10, roundedDecimalPlaces)}${match[3]}`
        }
      }
    }

    const displayValueDims = renderingUtils.getTextDimensions(displayValue, font)
    const showBaseValue =
      ((displayValueDims.height + convertedValueDims.height) <= availableHeight) &&
      (displayValueDims.width <= availableWidth && convertedValueDims.width <= availableWidth)
    this.setState({
      ...this.state,
      displayValue: displayValue,
      showBaseValue: showBaseValue,
      showConvertedValue: showBaseValue, // they're always shown or hidden together at this point
    })
  }

  componentDidMount() {
    this.determineVisibility()
  }

  render() {
    const node = this.props.node
    return (
      <div
        className={`${this.props.class} ${node.currency} ${this.props.node.balance === undefined || this.props.node.balance === 0 ? 'empty' : ''}`}
        style={this.props.styleFunction ? this.props.styleFunction(node) : {}}
        onClick={() => {
          this.props.tileClickHandler(node.currency)
        }}
      >
        <div ref={this.contentBox} className="leaf-content">
          <div className="leaf-header">{node.header}</div>
          <div className="leaf-hover-content">
            {node.balance > 0 ? (
              <React.Fragment>
                {this.state.showBaseValue && (
                  <PrivacyField text={this.state.displayValue}/>
                )}
                {this.state.showConvertedValue && (
                  <PrivacyField text={this.state.formattedCadValue}/>
                )}
                <div className="eye-icon-white"/>
              </React.Fragment>
            ) : (
              <span className="deposit-asset-cta">Deposit Assets</span>
            )}
          </div>
          {node.balance > 0 &&
            <div className="leaf-footer">{node.footer}</div>
          }
        </div>

      </div>
    )
  }
}

AssetTile.propTypes = {
  node: PropTypes.object,
  class: PropTypes.string,
  styleFunction: PropTypes.func,
  tileClickHandler: PropTypes.func,
}

AssetTile.defaultProps = {
  class: 'tile-map-leaf',
  tileClickHandler: () => {
  },
}
