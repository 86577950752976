import React from "react"
import PropTypes from "prop-types"

import { ChartCanvas, Chart } from "react-stockcharts"
import { XAxis, YAxis } from "react-stockcharts/lib/axes"
import { AreaSeries } from "react-stockcharts/lib/series"
import { curveMonotoneX } from "d3-shape"
import { scaleTime } from "d3-scale"
import { MouseCoordinateX } from "react-stockcharts/lib/coordinates"
import { format } from "d3-format"
import { timeFormat } from "d3-time-format"

import VerticalCursor from './VerticalCursor'
import { money } from '../../util/formatting'
import * as color from '../../util/color'

class AreaChart extends React.Component {
  render () {
    const { width, height, data, fillColor, lineColor, cursorColor, xAxisFormatter } = this.props
    const margin = { left: 0, right: 46, top: 20, bottom: 16 }

    const graphWidth = width - margin.left - margin.right
    const graphHeight = height - margin.top - margin.bottom

    // use this for drawing the Y axis labels
    const dataYMin = Math.min.apply(Math, data.map(function(o) { return o.cad_value }))
    const dataYMax = Math.max.apply(Math, data.map(function(o) { return o.cad_value }))

    // use this for adding some vertical padding to the graph
    const displayYMin = dataYMin - ((dataYMax - dataYMin) * 0.2)
    const displayYMax = dataYMax + ((dataYMax - dataYMin) * 0.2)

    const yLabelBgWidth = 45

    const displayXLabel = (currentItem) => {
      let date = timeFormat("%b %e, %Y")(currentItem.date)
      let cadValue = money(currentItem.cad_value)
      return date + ": " + cadValue
    }

    const yAxisFormatter = (yValue) => {
      if (yValue < 1 && yValue > 0) {
        return format("$.2f")(yValue)
      }
      return format("$.3s")(yValue)
    }

    return (
      <div className="area-chart">
        <ChartCanvas
          ratio={3} width={width} height={height}
          margin={margin}
          seriesName="DAC"
          data={data}
          type='svg'
          xAccessor={d => d.date}
          displayXAccessor={displayXLabel}
          xScale={scaleTime()}
          xExtents={[data[0].date, data[data.length-1].date]}
          disableInteraction={true}>

          <Chart id={0} yExtents={[displayYMin, displayYMax]}>
            <g transform="translate(5, -10)">
              <XAxis className="x-axis"
                axisAt="top" orient="top" tickFormat={xAxisFormatter}
                ticks={7} innerTickSize={0} outerTickSize={0} tickPadding={0}
                zoomEnabled={false} />
            </g>

            <g transform="translate(7, 0)">
              <rect width={yLabelBgWidth} height={graphHeight} style={{fill: 'rgba(131,198,234,0.3)'}} transform={"translate(" + (graphWidth-7) +", 0)"} />
              <YAxis className="y-axis"
                axisAt="right" orient="right"
                tickFormat={yAxisFormatter}
                tickValues={[dataYMin, (dataYMin + dataYMax) / 2, dataYMax]}
                ticks={3} innerTickSize={0} outerTickSize={0} tickPadding={0}
                zoomEnabled={false} />
            </g>

            <g>
              <rect width={graphWidth} height={graphHeight} style={{fill: 'rgba(131,198,234,0.15)'}} />
              <AreaSeries
                className="main-series"
                yAccessor={d => d.cad_value}
                interpolation={curveMonotoneX}
                opacity={1} />
            </g>

            <g>
              <rect width={graphWidth + yLabelBgWidth} height={16} transform={"translate(0, " + graphHeight +")"} />
              <VerticalCursor
                className="vertical-cursor"
                strokeDasharray="Solid"
                snapX={true}
                yAccessor={d => d.cad_value}/>

              <MouseCoordinateX
                className="mouse-coordinate-x"
                at="bottom"
                orient="bottom"
                displayFormat={d => d}
                snapX={true}
                rectWidth={130}
                rectHeight={15}
                fontSize={10} />
            </g>
          </Chart>
        </ChartCanvas>
      </div>
    );
  }
}

AreaChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  fillColor: PropTypes.string,
  lineColor: PropTypes.string,
  cursorColor: PropTypes.string,
  xAxisFormatter: PropTypes.func.isRequired,
}

AreaChart.defaultProps = {
  fillColor: '#99A4AE',
  lineColor: '#465262',
  cursorColor: '#465262',
}

export default AreaChart
