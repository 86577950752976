import React from "react"
import {money, variableDecimalDigits} from './formatting'
import Cookies from 'universal-cookie'

const DEFAULT_PRIVACY_COOKIE = '_balance_is_privacy_enabled'
const cookies = new Cookies()
const DEFAULT_PRIVACY = false
export const PrivacyContext = React.createContext(DEFAULT_PRIVACY)

export const getSessionValue = () => {
  const sessionValue = cookies.get(DEFAULT_PRIVACY_COOKIE)
  if (sessionValue === undefined) {
    return DEFAULT_PRIVACY
  }

  return sessionValue === "true"
}

export const setSessionValue = (isPrivacyEnabled) => {
  cookies.set(DEFAULT_PRIVACY_COOKIE, isPrivacyEnabled, { path: '/' })
}
