import React from "react"
import PropTypes from "prop-types"

import {timeFormat} from "d3-time-format"
import {CSSTransitionGroup} from 'react-transition-group'
import ReactHintFactory from 'react-hint'

const ReactHint = ReactHintFactory(React)

import AreaChart from '../graph/AreaChart'

import * as format from '../../util/formatting'
import * as stats from '../../util/stats'
import * as userHelpers from "../../util/users"
import {PrivacyField} from "../graph/PrivacyField";
import * as formatting from "../../util/formatting";
import CopyableText from "../common/CopyableText";
import CopyBtn from "../common/CopyBtn";

class AssetDetails extends React.Component {
  constructor(props) {
    super(props)

    this.graphContainer = React.createRef()

    this.state = {
      graphReadyForDisplay: false,
      graphWidth: 0,
      graphHeight: 0,
      graphData: [],
      period: 'week',
      isSettlementClient: this.props.user.type === userHelpers.USER_TYPE_SETTLEMENT_CLIENT,
    }
  }

  getGraphData = (period) => {
    // Data for the weekly chart has a 2-hour resolution.
    // Data for the monthly chart has a 1-day resolution.
    // Data for the yearly chart has a 1-day resolution.
    // Data comes in the following format: {currency: [cad_price(1 week ago), cad_price(...+2 hours), cad_price(...+2 hours)...]}

    let pricesAccessor = () => {
      return this.props.assetSummary['prices_' + period]
    }

    let resolution = {
      week: 1000 * 60 * 60 * 2, // 2 hours
      month: 1000 * 60 * 60 * 24, // 1 day
      year: 1000 * 60 * 60 * 24, // 1 day
    }[period]

    let num_data_points = pricesAccessor().length
    let range_end_date = new Date() // now

    let data_period = []
    for (let i = 0; i < num_data_points; ++i) {
      let price = pricesAccessor()[i]
      if (!price) {
        price = pricesAccessor()[i - 1]
        if (!price) {
          next
        }
      }
      let cad_value = price // we always show just the price in this graph

      data_period.push({
        date: new Date(range_end_date.getTime() - resolution * (num_data_points - i - 1)),
        cad_value: cad_value,
      })
    }

    return data_period
  }

  getXAxisFormatter = (period) => {
    if (period === 'week') {
      return timeFormat("%a")
    }
    if (period === 'month') {
      return timeFormat("%b %e")
    }
    if (period === 'year') {
      return timeFormat("%b")
    }
  }

  getSharpeRatio = () => {
    return stats.sharpeRatio(this.props.assetSummary.prices_year)
  }

  getPeriodDiff = () => {
    const graphData = this.getGraphData(this.state.period)
    if (!graphData || !graphData.length) {
      return 0
    }

    const firstVal = graphData[0].cad_value
    const lastVal = graphData[graphData.length - 1].cad_value

    return ((lastVal / firstVal) - 1) * 100
  }

  updateDimensions = () => {
    this.setState({
      ...this.state,
      graphReadyForDisplay: true,
      graphWidth: this.graphContainer.current.offsetWidth,
      graphHeight: 170,
    })
  }

  setPeriod = (period) => {
    this.setState({
      ...this.state,
      period: period,
    })
  }

  handleKeypress = (event) => {
    switch (event.keyCode) {
      case 27: // escape
        event.preventDefault()
        this.props.onClose()
        break;
      case 37: // left arrow
        event.preventDefault()
        this.props.onPrevious()
        break;
      case 39: // right arrow
        event.preventDefault()
        this.props.onNext()
        break;
      default:
        return;
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
    window.addEventListener("keydown", this.handleKeypress)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
    window.removeEventListener("keydown", this.handleKeypress)
  }

  render() {
    const mainContent = (
      <React.Fragment>
        <div className="section-title-container">
          <div className="currency">{this.props.currency}</div>
          <div className="proportion">
            <span className="percentage">{format.percentage(this.props.proportion, 2)}</span>
            <span className="description">
              {this.state.isSettlementClient ? "of your total assets" : "of DAC"}
            </span>
          </div>
          <div className="close-btn" onClick={() => {
            this.props.onClose()
          }}></div>
        </div>

        <div className="details-container">
          <div className="header">
            <span className="asset-name">{this.props.assetSummary.full_name}</span>
            <span className="asset-value">
              <PrivacyField className="value"
                            text={format.variableDecimalDigits(this.props.data.balance, 0, 6)}/>
              <PrivacyField className="ticker" text={this.props.currency} defaultText=''/>
            </span>
          </div>
          <div className="portfolio-summary">
            <div className="public-address">
              <h2>Deposit Address</h2>
              <span className="address">
                <CopyableText
                  textToDisplay={this.props.data.public_address}
                  textToCopy={this.props.data.public_address}
                  tooltipPosition="top"
                ></CopyableText>
                <CopyBtn
                  textToCopy={this.props.data.public_address}
                  tooltipPosition="top"
                  ></CopyBtn>
              </span>
            </div>
            <div className="asset-value">
              <h2>Amount</h2>
              <PrivacyField className="value"
                            text={format.variableDecimalDigits(this.props.data.balance, 0, 6)}/>
              <PrivacyField className="ticker" text={this.props.currency} defaultText=''/>
            </div>
            <div className="cad-value">
              <h2>Value</h2>
              <PrivacyField className="value"
                            text={formatting.money(this.props.data.cad_value, 2)}/>
            </div>
          </div>

          <div className="asset-price-chart-container">
            <div className="graph-container" ref={this.graphContainer}>
              {this.state.graphReadyForDisplay && (
                <AreaChart
                  width={this.state.graphWidth}
                  height={this.state.graphHeight}
                  data={this.getGraphData(this.state.period)}
                  cursorColor={this.props.primaryColor}
                  xAxisFormatter={this.getXAxisFormatter(this.state.period)}/>)}
            </div>
            <div className="period-selector">
              <div className={"period " + (this.state.period === 'week' ? 'active' : '')}
                   onClick={e => this.setPeriod('week')}>
                <span className="period-label">1W</span>
                {this.state.period === 'week' && (
                  <span
                    className={"period-diff " + (this.getPeriodDiff() > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'month' ? 'active' : '')}
                   onClick={e => this.setPeriod('month')}>
                <span className="period-label">1M</span>
                {this.state.period === 'month' && (
                  <span
                    className={"period-diff " + (this.getPeriodDiff() > 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
              <div className={"period " + (this.state.period === 'year' ? 'active' : '')}
                   onClick={e => this.setPeriod('year')}>
                <span className="period-label">1Y</span>
                {this.state.period === 'year' && (
                  <span
                    className={"period-diff " + (this.getPeriodDiff() >= 0 ? 'positive' : 'negative')}>{format.signedPercentage(this.getPeriodDiff(), 2)}</span>
                )}
              </div>
            </div>
          </div>

          <hr/>

          <div className="description">{this.props.assetSummary.description}</div>
        </div>

        <ReactHint position="right" events className="tooltip"
                   attribute="data-asset-details-tooltip" delay={100} persist={true}/>
      </React.Fragment>
    )

    return (
      <CSSTransitionGroup
        transitionName="animated-pane-scale-in"
        transitionAppear={true}
        transitionAppearTimeout={250}
        transitionEnter={false}
        transitionLeave={false} component="div" className="asset-details-container">
        <div className={`asset-details ${this.props.currency}`}>
          {mainContent}
        </div>
      </CSSTransitionGroup>
    )
  }
}

AssetDetails.propTypes = {
  user: PropTypes.object,
  currency: PropTypes.string,
  assetSummary: PropTypes.object,
  primaryColor: PropTypes.string,
  proportion: PropTypes.number,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
}

export default AssetDetails
