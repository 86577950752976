import React from "react"
import PropTypes from "prop-types"
import {PrivacyContext} from "../../util/privacy";


export class PrivacyField extends React.Component {
  render() {
    return (
      <PrivacyContext.Consumer>
        {(privacyEnabled) => {
          if (privacyEnabled) {
            return (<span className="amount-hidden">{this.props.defaultText}</span>)
          } else {
            return (<span className={this.props.className ? this.props.className : ''}>{this.props.text || this.props.defaultText}</span>)
          }
        }}
      </PrivacyContext.Consumer>

    )
  }
}

PrivacyField.propTypes = {
  text: PropTypes.string,
  defaultText: PropTypes.string,
  className: PropTypes.string,
}

PrivacyField.defaultProps = {
  defaultText: '(hidden)'
}
